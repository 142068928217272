/**
 *
 * @param {string} endpoint
 * @param {object} paramValues
 */
export const addParamValue = (endpoint, paramValues) => {
    const paramNames = endpoint.split('/').filter((path) => path.includes(':')).map((path) => path.split('').filter((char) => char !== ':').join(''));
    return paramNames.reduce((acc, paramName) => {
        const paramValue = paramValues?.[paramName];
        return acc.split('/').map((path) => path === `:${paramName}` ? paramValue : path).join('/');
    }, endpoint);
}
