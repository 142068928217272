import {ENDPOINTS} from "@/constants/endpoints";
import {axios} from "@/utils/axios";

export const exportPlacementReports = async (payload) => {
  const { from, to, reportType, fileType } = payload;
  return await axios.post(
    `${ENDPOINTS.CUSTOM_REPORTS}`,
    {
      from, to, type: reportType
    },
    {
      responseType: fileType === 'csv' ? 'blob' : 'arraybuffer',
    }
  );
}