/**
 * @typedef LocationState
 * @property {Array<Location[]>} locations
 * @property {boolean} loading
 */

import { cacheAction } from "vuex-cache";
import { getLocations } from "../../helpers/location/location.service";

/**  @type {LocationState} */
export const state = {
  locations: [],
  filter: {},
  loading: false,
};

export const mutations = {
  /**
   * @param {LocationState} state
   * @param {boolean} value
   */
  SET_LOADING: (state, value) => {
    state.loading = value;
  },
  /**
   * @param {LocationState} state
   * @param {Location[]} value
   */
  SET_LOCATIONS: (state, value) => {
    state.locations = value;
  },
};

export const actions = {
  fetchLocations: cacheAction(async ({ cache, commit }) => {
    const locations = await cache.dispatch("FETCH_LOCATIONS");

    locations.sort((a, b) => a.name.localeCompare(b.name));

    commit("SET_LOCATIONS", locations);
  }),

  FETCH_LOCATIONS: async ({ commit }) => {
    commit("SET_LOADING", true);

    const locations = await getLocations();

    commit("SET_LOADING", false);

    return locations;
  },
};
