<script setup>
import { defineProps } from "vue";

const props = defineProps({
  spinning: Boolean,
  position: {
    type: String,
    validator: (value) => ["top", "center"].includes(value),
    default: "top",
  },
  message: String,
});
</script>

<template>
  <div class="spinner__container">
    <div
      class="spinner__wrapper"
      :class="{
        'spinner--top': props.position === 'top',
        'spinner--center': props.position === 'center',
      }"
      v-if="props.spinning"
    >
      <div>
        <b-spinner variant="primary"></b-spinner>
      </div>
      <p class="message" v-if="props.message">{{ message }}</p>
    </div>

    <slot />
  </div>
</template>

<style lang="scss">
.spinner {
  &__container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__wrapper {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background-color: rgba(255, 255, 255, 0.6);
    z-index: 4;

    .message {
      color: rgb(109, 109, 109);
    }
  }

  &--top {
    display: flex;
    align-items: center;
    padding-top: 10%;
    flex-direction: column;
    gap: 1rem;
  }

  &--center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
  }
}
</style>
