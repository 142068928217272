import { exportPlacementReports } from "@/services/custom-reports.service";

export const state = {
};

export const getters = {

};

export const mutations = {
};

export const actions = {
  placementReports: async ({ commit, state, dispatch }, { data, onSuccess, onError }) => {
    try {
      const response = await exportPlacementReports(data);
      if (onSuccess) onSuccess(response);
    } catch (error) {
      if (onError) onError(error);
    }
  },
};
