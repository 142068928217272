import { cacheAction } from "vuex-cache";
import { getPositions } from "../../helpers/position/position.service";

/**
 * @typedef PositionState
 * @property {Array<Position>} positions
 */

/** @type {PositionState} */
export const state = {
  positions: [],
  filter: {},
  loading: false,
};

export const mutations = {
  /**
   * @param {PositionState} state
   * @param {boolean} value
   */
  SET_LOADING: (state, value) => {
    state.loading = value;
  },
  /**
   * @param {PositionState} state
   * @param {Position[]} value
   */
  SET_POSITIONS: (state, value) => {
    state.positions = value;
  },
};

export const actions = {
  fetchPositions: cacheAction(async ({ cache, commit }) => {
    const positions = await cache.dispatch("FETCH_POSITIONS");

    commit("SET_POSITIONS", positions);
  }),

  FETCH_POSITIONS: async ({ commit }) => {
    commit("SET_LOADING", true);

    const positions = await getPositions();

    commit("SET_LOADING", false);

    return positions;
  },
};
