/**
 *
 * @param {string} urlString - dirty url. example: https://example.com//shift -> https://example.com/shift
 * @param {boolean} isPath - this will determine if 'urlString' is a complete url (https://example.com) or just the path (/shift/data)
 * @returns normalized url
 */
export const normalizeUrl = (urlString, isPath = false) => {
  try {
    if (isPath) {
      return urlString.replace(/\/{2,}/g, "/");
    }

    const url = new URL(urlString);

    // Remove double slashes in the pathname
    url.pathname = url.pathname.replace(/\/{2,}/g, "/");

    // Add back the scheme if it had double slashes
    if (url.protocol.endsWith(":")) {
      url.protocol = url.protocol.replace(/:$/, "//");
    }

    return url.href;
  } catch (error) {
    if (error instanceof TypeError) {
      console.error(
        `ERROR:normalizeUrl() - Invalid URL String '${urlString}' `
      );
      console.error(error);
    }

    return urlString;
  }
};

export const sanitizeUrl = (url) => {
  if (url.startsWith("http://") || url.startsWith("https://")) {
    return url;
  } else {
    return "https://" + url;
  }
};
