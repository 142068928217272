import { format } from "date-fns";
import { createShift, getShift } from "../../helpers/shift/shift.service";
import "@/types/shift.js";
import { cacheAction } from "vuex-cache";

/**
 * @typedef ShiftState
 * @property {Array<Shift>} shifts
 * @property {{
 *  date: {
 *    start: Date | null,
 *    end: Date | null
 *  }
 * }} filter
 * @property {boolean} loading
 * @property {boolean} submitting
 */

/**
 * @type {ShiftState}
 */
export const state = {
  loading: false
};

export const mutations = {
  SET_LOADING: (state, value) => {
    state.loading = value;
  },
};

export const actions = {
};
