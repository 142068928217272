import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import AOS from "aos";
import "aos/dist/aos.css";
import i18n from "./i18n";
import store from "./state/store";

import BootstrapVue3 from "bootstrap-vue-3";
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from "maska";

import VueFeather from "vue-feather";
import Particles from "particles.vue3";

import { VueFire, VueFireAuth } from "vuefire";
import { getFirebaseConfig } from "./firebase/app.js";

import { appLoading } from "@/composables/loading";

import "@/assets/scss/config/saas/app.scss";
import "@vueform/slider/themes/default.css";
import "@/assets/scss/mermaid.min.css";
import { VueQueryPlugin } from "@tanstack/vue-query";

AOS.init({
  easing: "ease-out-back",
  duration: 1000,
});


const firebaseApp = getFirebaseConfig();
const app = createApp(App)
  .use(store)
  .use(VueApexCharts)
  .use(BootstrapVue3)
  .component(VueFeather.type, VueFeather)
  .use(Maska)
  .use(Particles)
  .use(i18n)
  .use(vClickOutside)
  .use(VueQueryPlugin)
  .use(VueFire, {
    // imported above but could also just be created here
    firebaseApp,
  modules: [
      // we will see other modules later on
      VueFireAuth(),
    ],
  })

appLoading.value = true;

router.afterEach(() => {
  appLoading.value = false;
});

app.use(router);

app.mount("#app");
