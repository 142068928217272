import { Buffer } from "buffer";
export const CONFIG = {
  APP_NAME: 'client-portal',
  APP_URL: process.env.VUE_APP_URL,
  BASE_URL: process.env.VUE_APP_API_URL,
  ONDEMAND_API: process.env.VUE_APP_ONDEMAND_API_URL,
  JWT_EXPIRATION: process.env.VUE_APP_JWT_TOKEN_EXPIRATION,
  JWT_SECRET: process.env.VUE_APP_JWT_SECRET_KEY,
  FIREBASE: {
    API_KEY: process.env.VUE_APP_FIREBASE_API_KEY,
    AUTH_DOMAIN: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    DATABASE_URL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    PROJECT_ID: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    STORAGE_BUCKET: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    MESSAGING_SENDER_ID: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  },
  GOOGLE: {
    MAPS_API_KEY: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
  },
  CANDIDATES_API: process.env.VUE_APP_CANDIDATES_API_URL,
  POSTGRE_API: process.env.VUE_APP_POSTGRE_API_URL,
  TEMPWORKS_API: process.env.VUE_APP_TEMPWORKS_API,
  ONBOARDING_API: process.env.VUE_APP_ONBOARDING_API_URL,
  FB_TYPE: {
    0: JSON.parse(Buffer.from(process.env.VUE_APP_FIREBASE_PROJECT_CONF0, 'base64').toString('ascii')),
    1: JSON.parse(Buffer.from(process.env.VUE_APP_FIREBASE_PROJECT_CONF1, 'base64').toString('ascii')),
    2: JSON.parse(Buffer.from(process.env.VUE_APP_FIREBASE_PROJECT_CONF2, 'base64').toString('ascii')),
    3: JSON.parse(Buffer.from(process.env.VUE_APP_FIREBASE_PROJECT_CONF3, 'base64').toString('ascii')),
    4: JSON.parse(Buffer.from(process.env.VUE_APP_FIREBASE_PROJECT_CONF4, 'base64').toString('ascii')),
    5: JSON.parse(Buffer.from(process.env.VUE_APP_FIREBASE_PROJECT_CONF5, 'base64').toString('ascii')),
    6: JSON.parse(Buffer.from(process.env.VUE_APP_FIREBASE_PROJECT_CONF6, 'base64').toString('ascii')),
  },
  AWS_ID_KEY: process.env.AWS_ID_KEY,
  AWS_SECRET_KEY: process.env.AWS_SECRET_KEY,
}