import {addParamValue} from "@/utils/addParamValue";
import {ENDPOINTS} from "@/constants/endpoints";
import {axios} from "@/utils/axios";

export const createSettings = async (companyId, payload) => {
    const endpoint = addParamValue(ENDPOINTS.SETTINGS, {
        company_id: companyId,
    });

    const { logo, rateShift, ...primary} = payload;
    const formData = new FormData();

    if (logo) formData.append('logo', logo)

    const primarySettings = JSON.stringify(primary);
    const rateShiftSettings = JSON.stringify(rateShift);

    if (primarySettings) formData.append('primary', primarySettings);
    if (rateShiftSettings) formData.append('rate_shift', rateShiftSettings);

    return await axios.post(endpoint, formData);
}

export const updateSettings = async (companyId, payload, params) => {
    const endpoint = addParamValue(ENDPOINTS.SETTINGS, {
        company_id: companyId,
    });

    const { logo, rateShift, timesheets, placements, orders, integrations, ...primary} = payload;
    const formData = new FormData();

    if (logo) formData.append('logo', logo)

    const primarySettings = JSON.stringify(primary);
    const rateShiftSettings = JSON.stringify(rateShift);
    const timesheetsSettings = timesheets ? JSON.stringify(timesheets) : null;
    const placementsSettings = placements ? JSON.stringify(placements) : null;
    const ordersSettings = orders ? JSON.stringify(orders) : null;
    const integrationsSettings = integrations ? JSON.stringify(integrations) : null;

    if (primarySettings) formData.append('primary', primarySettings);
    if (rateShiftSettings) formData.append('rate_shift', rateShiftSettings);
    if (timesheetsSettings) formData.append('timesheets', timesheetsSettings);
    if (placementsSettings) formData.append('placements', placementsSettings);
    if (ordersSettings) formData.append('orders', ordersSettings);
    if (integrationsSettings) formData.append('integrations', integrationsSettings);

    return await axios.put(endpoint, formData, {
        params
    });
}

export const updateMaintenanceModeSettings = async (data) => {
    const endpoint = addParamValue(ENDPOINTS.SETTINGS + '/maintenance-mode', {
        company_id: data.company_id,
        maintenance_mode: data.maintenance_mode
    });

    return await axios.put(endpoint, data);
}

export const syncEvereeCandidates = async (data) => {
    const endpoint = addParamValue(ENDPOINTS.SETTINGS + '/everee/sync-candidates', {
        company_id: data.company_id
    });

    return await axios.put(endpoint, { syncType: data.syncType });
}

/**
 *
 * @param {string} companyId
 * @returns
 */
export const getPublicSettings = async (companyId) => {
    
    try {
      const endpoint = addParamValue(ENDPOINTS.SETTINGS_PUBLIC, {
        company_id: companyId,
      });
  
      const response = await axios.get(endpoint);
  
      if (!response.data?.data) throw new Error("Can't client portal settings");
  
      return response.data.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        console.error(error.response.data.message ?? error.message);
      }
  
      console.error(error);
    }
  };