import { createStore } from "vuex";
import createCache from "vuex-cache";

import modules from "./modules";

const store = createStore({
  modules,
  plugins: [createCache({ timeout: 2 * 60 * 60 * 1000 })],
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== "production",
});

export default store;
