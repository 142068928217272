import { initializeApp, getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { CONFIG } from "@/constants/config";
const firebase = []; //singleton

const DEFAULT_FIREBASE_TYPE = 1;
/**
 * getFirebaseConfig - Get Firebase App instance
 * @param {Number} fbType StaffUpApp Firebase Environment
 * @returns FirebaseApp instance
 */
export function getFirebaseConfig (fbType = 1) {
    const firebaseInstance = firebase.find((fb) => fb.type === fbType);

    if (!!firebaseInstance && firebaseInstance?.app) {
        return firebaseInstance?.app;
    }

    if (fbType === DEFAULT_FIREBASE_TYPE) {
        const firebaseApp = initializeApp(CONFIG.FB_TYPE[fbType])
        firebase.push({type: fbType, app: firebaseApp});
        return firebaseApp;
    }

    const firebaseApp = initializeApp(CONFIG.FB_TYPE[fbType], `firebase_env_${fbType}`)
    firebase.push({type: fbType, app: firebaseApp});
    return firebaseApp;
}

/**
 * getFbConfig - Get Firebase App instance
 * @param {Number} fbType StaffUpApp Firebase Environment
 * @returns FirebaseApp instance
 */
export function getFbConfig (fbType) {
 try {
  // Get firebase app
  return getApp(`fb_type_${fbType}`)
 } catch (error) {
  // If app doesn't exist, initialize it
  // console.log(`Firebase for FB_TYPE ${fbType} hasn't yet been initialized. Initializing now...`)
  return initializeApp(CONFIG.FB_TYPE[fbType], `fb_type_${fbType}`)
 }
}
