import axios from "axios";

export const state = {
  customFields: [],
  moduleType: null,
};

export const getters = {
  getCustomFields: (state) => state.customFields,
  getModuleType: (state) => state.moduleType
};

export const mutations = {
  setCustomFields(state, newValue) {
    state.customFields = newValue;
  },
  setModuleType(state, newValue) {
    console.log('>>>SETMODULE', newValue)
    state.moduleType = newValue;
  }
};

export const actions = {
   async createCustomFields({ dispatch, commit }, fields) {
     commit("setCustomFields", fields)
   },

   createModuleType({dispatch, commit}, fields) {
    console.log('>>>STORE', fields)
    commit("setModuleType", fields)
   }
};
