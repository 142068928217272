import { cacheAction } from "vuex-cache";
import {
  getSingleWhosWorking,
  getWhosWorking,
} from "../../services/whos-working.service";

export const state = {
  list: [],
  single: null,
  listTotal: 0,
  loading: {
    list: false,
    single: false,
  },
  activeRequests: 0,
  previousPayload: {
    list: undefined,
    single: undefined,
  },
};

export const mutations = {
  SET_LIST(state, data) {
    state.list = data;
  },
  SET_SINGLE(state, data) {
    state.single = data;
  },
  SET_LOADING(state, { key, value }) {
    state.loading[key] = value;
  },
  SET_PREVIOUS_PAYLOAD(state, { key, value }) {
    state.previousPayload[key] = value;
  },
  SET_ACTIVE_REQUEST: (state, count) => {
    state.activeRequests = count;
  },
  SET_LIST_TOTAL: (state, total) => {
    state.listTotal = total;
  },
};

export const actions = {
  fetchList: cacheAction(async ({ commit, cache }, payload) => {
    commit("SET_PREVIOUS_PAYLOAD", { key: "list", payload });

    commit("SET_LOADING", { key: "list", value: true });

    const response = await cache.dispatch("GET_LIST", payload);
    console.log(response)
    if (state.activeRequests === 0) {
      commit("SET_LOADING", { key: "list", value: false });
      commit("SET_LIST", response?.data || []);
      commit("SET_LIST_TOTAL", response?.meta?.total || 0);
    }
  }),

  GET_LIST: async ({ commit, state }, payload) => {
    // This will increment active request
    // This is to manage request so loading will not be 'false' if active request is greater than 1
    commit("SET_ACTIVE_REQUEST", state.activeRequests + 1);

    const whosWorkingHistory = await getWhosWorking(payload, true);

    // This will decrement after the request was done
    commit("SET_ACTIVE_REQUEST", state.activeRequests - 1);

    return whosWorkingHistory;
  },

  fetchSingle: cacheAction(async ({ commit, cache }, payload) => {
    commit("SET_SINGLE", null);

    commit("SET_PREVIOUS_PAYLOAD", { key: "single", payload });

    commit("SET_LOADING", { key: "single", value: true });

    const response = await cache.dispatch("GET_SINGLE", payload);

    commit("SET_LOADING", { key: "single", value: false });

    commit("SET_SINGLE", response.data);
  }),

  GET_SINGLE: async (_, payload) => {
    const whosWorking = await getSingleWhosWorking(payload);

    return whosWorking;
  },

  invalidateList: cacheAction(async ({ cache, state, dispatch }) => {
    const isCleared = cache.clear("GET_LIST");

    if (isCleared) {
      dispatch("fetchList", state.previousPayload.list);
    }
  }),

  invalidateSingle: cacheAction(async ({ cache, state, dispatch }) => {
    const isCleared = cache.clear("GET_SINGLE");

    if (isCleared) {
      dispatch("fetchSingle", state.previousPayload.single);
    }
  }),
};
