import { AxiosError } from "axios";
import { ENDPOINTS } from "@/constants/endpoints";
import { generateOnDemandToken } from "@/utils/generateOnDemandToken";
import { axios } from "@/utils/axios";

/**
 *
 * @typedef GetShiftPayload
 * @property {string} startDate
 * @property {string} endDate
 * @property {string} position
 * @property {string} location
 */

/**
 * @param {GetShiftPayload} payload
 */
export const getShift = async (payload) => {
  try {
    const ondemandToken = await generateOnDemandToken();

    const response = await axios.get(ENDPOINTS.SHIFT.GET, {
      params: {
        ...payload,
      },
      headers: {
        Authorization: ondemandToken,
      },
    });

    return response.data?.data || [];
  } catch (error) {
    if (error instanceof AxiosError) {
      console.error(error.message);
      return;
    }

    console.error(error);
  }
};

/**
 * Create new Shift as draft
 * @param {ShiftPayload} payload
 */
export const createShift = async (payload) => {
  try {
    const ondemandToken = await generateOnDemandToken();

    const response = await axios.post(
      ENDPOINTS.SHIFT.CREATE,
      {
        ...payload,
        app: "client-portal",
        draft: true,
      },
      {
        headers: {
          Authorization: ondemandToken,
        },
      }
    );

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      console.error(error.message);
      return;
    }

    console.error(error);
  }
};
