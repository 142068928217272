import axios from "axios";
import { addParamValue } from "@/utils/addParamValue";
import { AxiosError } from "axios";
import { generateOnDemandToken } from "@/utils/generateOnDemandToken";
import { ENDPOINTS } from "@/constants/endpoints";
import { COMMON } from "@/constants/common";

/**
 * @returns {Promise<Location[]>}
 */
export const getLocations = async () => {
  try {
    const ondemandToken = await generateOnDemandToken();

    const response = await axios.get(ENDPOINTS.LOCATION.GET, {
      headers: {
        Authorization: ondemandToken,
      },
    });

    return response.data?.data || [];
  } catch (error) {
    if (error instanceof AxiosError) {
      console.error(error.message);
      return;
    }

    console.error(error);
  }
};

export const getLocationContacts = async (locationId) => {
  const endpoint = addParamValue(ENDPOINTS.LOCATION.CONTACTS, {
    locationId,
  });

  const { data } = await axios.get(endpoint, {
    headers: {
      'x-app': 'placements-api',
      Authorization: localStorage.getItem(COMMON.ACCESS_TOKEN)
    }
  });

  return data;
}
