import { AxiosError } from "axios";
import { ENDPOINTS } from "@/constants/endpoints";
import { axios } from "@/utils/axios";

/**
 * @typedef UserResponse
 * @property {string} _id
 * @property {string} username
 * @property {string} company_id
 * @property {string} role_id
 * @property {string} created_at
 * @property {string} updated_at
 * @property {string} first_name
 * @property {string} last_name
 * @property {string} password
 * @property {string} phone
 * @property {number} is_activate
 * @property {number} is_disable
 * @property {string} token
 * @property {string} logoBase64
 */

/**
 *
 * @param {string} id - user id
 * @returns {(UserResponse|undefined)}
 */
export const getUserDetails = async () => {
  try {
    const response = await axios.get(ENDPOINTS.USER.DETAILS);


    if (!response.data?.data) throw new Error("Can't find user details");

    return response.data.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      console.error(error.response.data.message ?? error.message);
    }

    console.error(error);
  }
};
