export const state = {
  type: null,
  message: null,
  placementLastUpdate: null,
  placementFromNotification: null
};

export const mutations = {
  success(state, message) {
    state.type = "alert-success";
    state.message = message;
  },
  error(state, message) {
    state.type = "alert-danger";
    state.message = message;
  },
  clear(state) {
    state.type = null;
    state.message = null;
  },
  placementNotification (state, timestamp) {
    state.placementLastUpdate = timestamp
  },
  openPlacementNotification (state, placement) {
    state.placementFromNotification = placement
  }
};

export const actions = {
  success({ commit }, message) {
    commit("success", message);
  },
  error({ commit }, message) {
    commit("error", message);
  },
  clear({ commit }) {
    commit("clear");
  },
};
