import axios from "axios";
import { AxiosError } from "axios";
import { generateOnDemandToken } from "@/utils/generateOnDemandToken";
import { ENDPOINTS } from "../../constants/endpoints";

/**
 *
 * @returns {Promise<Position[]>}
 */
export const getPositions = async () => {
  try {
    const ondemandToken = await generateOnDemandToken();

    const response = await axios.get(ENDPOINTS.POSITION.GET, {
      headers: {
        Authorization: ondemandToken,
      },
    });

    return response.data?.data || [];
  } catch (error) {
    if (error instanceof AxiosError) {
      console.error(error.message);
      return;
    }

    console.error(error);
  }
};
