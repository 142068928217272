import { computed } from "vue";
import { useStore } from "vuex";
import { colorShade } from "../../../utils/colorShade";
import { hexToRgb } from "../../../utils/hexToRgb";

export const useColorTheme = () => {
  const store = useStore();

  const primaryColor = computed(
    () => store.getters["settings/getPrimaryColor"]
  );
  const secondaryColor = computed(
    () => store.getters["settings/getSecondaryColor"]
  );

  const initialize = () => {
    const styleTag = document.createElement("style");
    styleTag.type = "text/css";

    const primary = hexToRgb(primaryColor.value);
    const primarySelected = hexToRgb(colorShade(primaryColor.value, -10));
    const secondary = hexToRgb(secondaryColor.value);

    styleTag.innerHTML = `
      :root {
        --vz-primary: ${primaryColor.value} !important;
        --vz-primary-rgb: ${primary.r}, ${primary.g}, ${primary.b} !important;

        --vz-primary-selected: ${colorShade(primaryColor.value, -10)} !important;
        --vz-primary-selected-rgb: ${primarySelected.r}, ${primarySelected.g}, ${primarySelected.b} !important;
            
        --vz-secondary: ${secondaryColor.value} !important;
        --vz-secondary-rgb: ${secondary.r}, ${secondary.g}, ${secondary.b} !important;

        --vz-input-focus-border: rgba(${primary.r}, ${primary.g}, ${primary.b}, 0.4);

        --vz-link-color: ${colorShade(primaryColor.value, 10)};
        --vz-link-hover-color:  ${colorShade(primaryColor.value, 15)};

        --vz-topnav-item-color-active: ${colorShade(primaryColor.value, -5)} !important;
        --vz-vertical-menu-item-hover-color: ${colorShade(primaryColor.value, -5)} !important;
        --vz-vertical-menu-item-active-color: ${colorShade(primaryColor.value, -5)} !important;
        --vz-vertical-menu-sub-item-hover-color: ${colorShade(primaryColor.value, -5)} !important;
        --vz-vertical-menu-sub-item-active-color: ${colorShade(primaryColor.value, -5)} !important;
      }
    `;

    const headTags = document.getElementsByTagName("head");
    const headTag = headTags[headTags.length - 1];
    headTag.appendChild(styleTag);
  };

  return {
    initialize,
  };
};
