import { computed } from "vue";
import { useStore } from "vuex";
import { colorShade } from "../../../utils/colorShade";
import tinycolor from "tinycolor2";

export const useButtonTheme = () => {
  const store = useStore();

  const primaryColor = computed(
    () => store.getters["settings/getPrimaryColor"]
  );
  const secondaryColor = computed(
    () => store.getters["settings/getSecondaryColor"]
  );

  const initPrimaryButton = () => {
    const styleTag = document.createElement("style");
    styleTag.type = "text/css";

    styleTag.innerHTML = `
      .btn-primary {
        --vz-btn-bg: ${primaryColor.value} !important;
        --vz-btn-border-color: ${primaryColor.value} !important;

        --vz-btn-hover-bg: ${colorShade(primaryColor.value, -5)} !important;
        --vz-btn-hover-border-color: ${colorShade(
          primaryColor.value,
          -5
        )} !important;

        --vz-btn-active-bg: ${colorShade(primaryColor.value, -8)} !important;
        --vz-btn-active-border-color: ${colorShade(
          primaryColor.value,
          -8
        )} !important;

        --vz-btn-disabled-bg: ${colorShade(primaryColor.value, 5)} !important;
        --vz-btn-disabled-border-color: ${colorShade(
          primaryColor.value,
          5
        )} !important;
      }

      .btn-outline-primary {
        --vz-btn-color: ${primaryColor.value} !important;
        --vz-btn-border-color: ${primaryColor.value} !important;
        --vz-btn-hover-color: #fff;
        --vz-btn-hover-bg: ${primaryColor.value} !important;
        --vz-btn-hover-border-color: ${primaryColor.value} !important;
        --vz-btn-focus-shadow-rgb: 134, 92, 226;
        --vz-btn-active-color: #fff;
        --vz-btn-active-bg: ${primaryColor.value} !important;
        --vz-btn-active-border-color: ${primaryColor.value} !important;
        --vz-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --vz-btn-disabled-color: ${primaryColor.value} !important;
        --vz-btn-disabled-bg: transparent;
        --vz-btn-disabled-border-color: ${primaryColor.value} !important;
      }
    `;

    const headTags = document.getElementsByTagName("head");
    const headTag = headTags[headTags.length - 1];
    headTag.appendChild(styleTag);
  };

  const initPrimarySelectedButton = () => {
    const styleTag = document.createElement("style");
    styleTag.type = "text/css";

    styleTag.innerHTML = `
      .btn-primary-selected {
        --vz-btn-bg: ${colorShade(primaryColor.value, -10)} !important;
        --vz-btn-border-color: ${colorShade(
          primaryColor.value,
          -10
        )} !important;

        --vz-btn-hover-bg: ${colorShade(primaryColor.value, -12)} !important;
        --vz-btn-hover-border-color: ${colorShade(
          primaryColor.value,
          -12
        )} !important;

        --vz-btn-active-bg: ${colorShade(primaryColor.value, -14)} !important;
        --vz-btn-active-border-color: ${colorShade(
          primaryColor.value,
          -14
        )} !important;

        --vz-btn-disabled-bg: ${colorShade(primaryColor.value, -14)} !important;
        --vz-btn-disabled-border-color: ${colorShade(
          primaryColor.value,
          -14
        )} !important;
      }
    `;

    const headTags = document.getElementsByTagName("head");
    const headTag = headTags[headTags.length - 1];
    headTag.appendChild(styleTag);
  };

  const initSecondaryButton = () => {
    const styleTag = document.createElement("style");
    styleTag.type = "text/css";

    const secondaryColorBrightness = tinycolor(
      secondaryColor.value
    ).getBrightness();
    const textColor = secondaryColorBrightness > 120 ? "#464646" : "#fff";
    const disabledTextColor =
      secondaryColorBrightness > 120 ? "#8a8a8a" : "#fff";
    const disabledBgShade = secondaryColorBrightness > 120 ? -5 : 5;

    styleTag.innerHTML = `
      .btn-secondary {
        --vz-btn-color: ${textColor} !important;
        --vz-btn-hover-color: ${textColor} !important;
        --vz-btn-disabled-color: ${disabledTextColor} !important;
        --vz-btn-bg: ${secondaryColor.value} !important;
        --vz-btn-border-color: ${secondaryColor.value} !important;

        --vz-btn-hover-bg: ${colorShade(secondaryColor.value, -5)} !important;
        --vz-btn-hover-border-color: ${colorShade(
          secondaryColor.value,
          -5
        )} !important;

        --vz-btn-active-bg: ${colorShade(secondaryColor.value, -8)} !important;
        --vz-btn-active-border-color: ${colorShade(
          secondaryColor.value,
          -8
        )} !important;

        --vz-btn-disabled-bg: ${colorShade(
          secondaryColor.value,
          disabledBgShade
        )} !important;
        --vz-btn-disabled-border-color: ${colorShade(
          secondaryColor.value,
          disabledBgShade
        )} !important;
      }

      .btn-outline-secondary {
        --vz-btn-color: ${secondaryColor.value} !important;
        --vz-btn-border-color: ${secondaryColor.value} !important;
        --vz-btn-hover-color: #fff;
        --vz-btn-hover-bg: ${secondaryColor.value} !important;
        --vz-btn-hover-border-color: ${secondaryColor.value} !important;
        --vz-btn-focus-shadow-rgb: 134, 92, 226;
        --vz-btn-active-color: #fff;
        --vz-btn-active-bg: ${secondaryColor.value} !important;
        --vz-btn-active-border-color: ${secondaryColor.value} !important;
        --vz-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --vz-btn-disabled-color: ${secondaryColor.value} !important;
        --vz-btn-disabled-bg: transparent;
        --vz-btn-disabled-border-color: ${secondaryColor.value} !important;
      }
    `;

    const headTags = document.getElementsByTagName("head");
    const headTag = headTags[headTags.length - 1];
    headTag.appendChild(styleTag);
  };

  const initialize = () => {
    initPrimaryButton();
    initPrimarySelectedButton();
    initSecondaryButton();
  };

  return {
    initialize,
  };
};
