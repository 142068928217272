import axios from "axios";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

const user = JSON.parse(localStorage.getItem('client-portal-user'));
if (user && user.token) {
  api.defaults.headers.common["Authorization"] = user.token;
}

export default api;
