import { format } from "date-fns";
import { createShift, getShift } from "../../helpers/shift/shift.service";
import "@/types/shift.js";
import { cacheAction } from "vuex-cache";

/**
 * @typedef ShiftState
 * @property {Array<Shift>} shifts
 * @property {{
 *  date: {
 *    start: Date | null,
 *    end: Date | null
 *  }
 * }} filter
 * @property {boolean} loading
 * @property {boolean} submitting
 */

/**
 * @type {ShiftState}
 */
export const state = {
  shifts: [],
  loading: false,
  submitting: false,
  activeRequests: 0,
  previousPayload: undefined,
};

export const mutations = {
  SET_SHIFTS(state, newValue) {
    state.shifts = newValue;
  },
  SET_LOADING: (state, value) => {
    state.loading = value;
  },
  SET_SUBMITTING: (state, value) => {
    state.submitting = value;
  },
  CLEAR_SHIFTS: (state) => {
    state.shifts = [];
  },
  SET_ACTIVE_REQUEST: (state, count) => {
    state.activeRequests = count;
  },
  SET_PREVIOUS_PAYLOAD: (state, previousPayload) => {
    state.previousPayload = previousPayload;
  },
};

export const actions = {
  fetchShifts: cacheAction(async ({ commit, cache }, payload) => {
    commit("SET_PREVIOUS_PAYLOAD", payload);

    const startDate = payload?.startDate;
    const endDate = payload?.endDate;

    const data = {
      endDate: endDate ? format(endDate, "yyyy-MM-dd") : undefined,
      startDate: startDate ? format(startDate, "yyyy-MM-dd") : undefined,
      position: payload?.position,
      location: payload?.location,
      status: payload?.status,
      search: payload?.search,
    };

    const response = await cache.dispatch("GET_SHIFTS", data);

    if (state.activeRequests === 0) {
      commit("SET_SHIFTS", response);
    }
  }),

  GET_SHIFTS: async ({ commit, state }, payload) => {
    // This will increment active request
    // This is to manage request so loading will not be 'false' if active request is greater than 1
    commit("SET_ACTIVE_REQUEST", state.activeRequests + 1);

    commit("SET_LOADING", true);

    const shifts = await getShift(payload);

    // This will decrement after the request was done
    commit("SET_ACTIVE_REQUEST", state.activeRequests - 1);

    // This will check if activeRequests is equal to 0
    // Then will set loading to false
    if (state.activeRequests === 0) {
      commit("SET_LOADING", false);
    }

    return shifts;
  },

  /**
   *
   * @param {*} param0
   * @param {{
   *  data: ShiftPayload,
   *  onSuccess: (response: any) => void,
   *  onError: (error: any) => void
   * }} param1
   */
  createShift: async ({ commit }, { data, onSuccess, onError }) => {
    commit("SET_SUBMITTING", true);

    try {
      const shift = await createShift(data);
      commit("SET_SUBMITTING", false);
      if (onSuccess) onSuccess(shift);
    } catch (error) {
      commit("SET_SUBMITTING", false);
      if (onError) onError(error);
    }
  },

  invalidateShift: cacheAction(async ({ cache, state, dispatch }) => {
    const isCleared = cache.clear("GET_SHIFTS");

    if (isCleared) {
      dispatch("fetchShifts", state.previousPayload);
    }
  }),
};
