import { useButtonTheme } from "./elements/button";
import { useColorTheme } from "./elements/color";
import { useSwitchTheme } from "./elements/switch";

const useTheme = () => {
  const { initialize: initializeButton } = useButtonTheme();
  const { initialize: initializeColor } = useColorTheme();
  const { initialize: initializeSwitch } = useSwitchTheme();

  const intitalizeTheme = () => {
    initializeButton();
    initializeColor();
    initializeSwitch();
  };

  return {
    intitalizeTheme,
  };
};

export default useTheme;
