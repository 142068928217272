import { cacheAction } from "vuex-cache";
import { getUserDetails } from "../../helpers/user/user.service";
import { COMMON } from "../../constants/common";

/**
 * @typedef UserState
 * @property {Array<any>} user
 */

/** @type {UserState} */
export const state = {
  user: undefined,
  loading: false,
};

export const getters = {
  getUserDetails: (state) => state.user,
  getCompanyId: (state) => state.user?.company_id,
};

export const mutations = {
  /**
   * @param {UserState} state
   * @param {boolean} value
   */
  SET_LOADING: (state, value) => {
    state.loading = value;
  },
  /**
   * @param {UserState} state
   * @param {any} value
   */
  SET_USER: (state, value) => {
    state.user = value;
  },
};

export const actions = {
  fetchUserDetails: cacheAction(async ({ cache, commit, dispatch }) => {
    let user = JSON.parse(sessionStorage.getItem(COMMON.USER));

    if (!user) {
      user = await cache.dispatch("FETCH_USERDETAILS");
      sessionStorage.setItem(COMMON.USER, JSON.stringify(user));
    }

    dispatch("settings/fetchSettings", user.company_id, { root: true });

    commit("SET_USER", user);
  }),

  FETCH_USERDETAILS: async ({ commit }) => {
    commit("SET_LOADING", true);

    const user = await getUserDetails();

    commit("SET_LOADING", false);

    return user;
  },
};
