import { axios } from "@/utils/axios";
import { ENDPOINTS } from "../constants/endpoints";
import { isAxiosError } from "axios";
6;

/**
 *
 * @param {object} payload filters
 * @param {Boolean} isHistory
 * @returns
 */
export const getWhosWorking = async (payload, isHistory) => {
  const historyEndpoint = `${ENDPOINTS.WHOSWORKING.BASE}?history=true`;
  const endpoint = isHistory ? historyEndpoint : ENDPOINTS.WHOSWORKING.BASE;

  try {
    const response = await axios.get(endpoint, {
      params: {
        ...payload,
      },
    });

    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      console.error(error.message);
      return;
    }

    console.error(error);
  }
};

/**
 *
 * @param {String} timesheetId
 * @param {Boolean} isHistory
 * @returns
 */
export const getSingleWhosWorking = async (payload) => {
  const endpoint = `${ENDPOINTS.WHOSWORKING.BASE}/${payload.timesheetId}?timecardId=${payload.timecardId}`;

  try {
    const response = await axios.get(endpoint);

    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      console.error(error.message);
      return;
    }

    console.error(error);
  }
};
