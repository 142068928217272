import { cacheAction } from "vuex-cache";
import {
  getSingleWhosWorking,
  getWhosWorking,
} from "../../services/whos-working.service";

export const state = {
  whosWorking: [],
  singleWhosWorking: null,
  loading: false,
  singleLoading: false,
  submitting: false,
  activeRequests: 0,
  previousPayload: {
    list: undefined,
    single: undefined,
  },
  whosWorkingTotal: 0,
};

export const mutations = {
  SET_WHOS_WORKING(state, newValue) {
    state.whosWorking = newValue;
  },
  SET_SINGLE_WHOS_WORKING(state, newValue) {
    state.singleWhosWorking = newValue;
  },
  SET_LOADING: (state, value) => {
    state.loading = value;
  },
  SET_SINGLE_LOADING: (state, value) => {
    state.singleLoading = value;
  },
  SET_SUBMITTING: (state, value) => {
    state.submitting = value;
  },
  CLEAR_WHOS_WORKING: (state) => {
    state.whosWorking = [];
  },
  SET_ACTIVE_REQUEST: (state, count) => {
    state.activeRequests = count;
  },
  SET_PREVIOUS_PAYLOAD: (state, { key, payload }) => {
    state.previousPayload[key] = payload;
  },
  SET_TOTAL_WHOS_WORKING: (state, total) => {
    state.whosWorkingTotal = total;
  },
};

export const actions = {
  fetchWhosWorking: cacheAction(async ({ commit, cache }, payload) => {
    commit("SET_PREVIOUS_PAYLOAD", { key: "list", payload });

    commit("SET_LOADING", true);

    const response = await cache.dispatch("GET_WHOS_WORKING", payload);

    if (state.activeRequests === 0) {
      commit("SET_LOADING", false);

      commit("SET_WHOS_WORKING", response.data);
      commit("SET_TOTAL_WHOS_WORKING", response.meta.total);
    }
  }),

  fetchUpdatedWhosWorking: cacheAction(async ({ commit, cache }, payload) => {
    commit("SET_PREVIOUS_PAYLOAD", { key: "list", payload });

    const response = await cache.dispatch("GET_WHOS_WORKING", payload);

    commit("SET_WHOS_WORKING", response.data);
    commit("SET_TOTAL_WHOS_WORKING", response.meta.total);
  }),

  GET_WHOS_WORKING: async ({ commit, state }, payload) => {
    // This will increment active request
    // This is to manage request so loading will not be 'false' if active request is greater than 1
    commit("SET_ACTIVE_REQUEST", state.activeRequests + 1);

    const whosWorking = await getWhosWorking(payload);

    // This will decrement after the request was done
    commit("SET_ACTIVE_REQUEST", state.activeRequests - 1);

    return whosWorking;
  },

  fetchSingleWhosWorking: cacheAction(async ({ commit, cache }, payload) => {
    commit("SET_SINGLE_WHOS_WORKING", null);

    commit("SET_PREVIOUS_PAYLOAD", { key: "single", payload });

    commit("SET_SINGLE_LOADING", true);

    const response = await cache.dispatch("GET_SINGLE_WHOS_WORKING", payload);

    commit("SET_SINGLE_LOADING", false);

    commit("SET_SINGLE_WHOS_WORKING", response.data);
  }),

  fetchUpdatedSingleWhosWorking: cacheAction(
    async ({ commit, cache }, payload) => {
      commit("SET_SINGLE_WHOS_WORKING", null);

      commit("SET_PREVIOUS_PAYLOAD", { key: "single", payload });

      const response = await cache.dispatch("GET_SINGLE_WHOS_WORKING", payload);

      commit("SET_SINGLE_WHOS_WORKING", response.data);
    }
  ),

  GET_SINGLE_WHOS_WORKING: async (_, payload) => {
    const whosWorking = await getSingleWhosWorking(payload);

    return whosWorking;
  },

  invalidateWhosWorking: cacheAction(async ({ cache, state, dispatch }) => {
    const isCleared = cache.clear("GET_WHOS_WORKING");

    if (isCleared) {
      dispatch("fetchWhosWorking", state.previousPayload.list);
    }
  }),

  updateSingleWhosWorking: cacheAction(async ({ dispatch, cache }) => {
    const isCleared = cache.clear("GET_SINGLE_WHOS_WORKING");

    if (isCleared) {
      dispatch("fetchUpdatedSingleWhosWorking", state.previousPayload.single);
    }
  }),

  updateWhosWorking: cacheAction(async ({ cache, state, dispatch }) => {
    const isCleared = cache.clear("GET_WHOS_WORKING");

    if (isCleared) {
      dispatch("fetchUpdatedWhosWorking", state.previousPayload.list);
    }
  }),
};
