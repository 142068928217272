export const useSwitchTheme = () => {
  const initPrimarySwitch = () => {
    const styleTag = document.createElement("style");
    styleTag.type = "text/css";

    styleTag.innerHTML = `
      .form-switch-primary .form-check-input:checked {
        background-color: var(--vz-primary) !important;
        border-color: var(--vz-primary) !important;
      }
    `;

    const headTags = document.getElementsByTagName("head");
    const headTag = headTags[headTags.length - 1];
    headTag.appendChild(styleTag);
  };

  const initPrimarySelectedSwitch = () => {
    const styleTag = document.createElement("style");
    styleTag.type = "text/css";

    styleTag.innerHTML = `
      .form-switch-primary-selected .form-check-input:checked {
        background-color: var(--vz-primary-selected) !important;
        border-color: var(--vz-primary-selected) !important;
      }
    `;

    const headTags = document.getElementsByTagName("head");
    const headTag = headTags[headTags.length - 1];
    headTag.appendChild(styleTag);
  };

  const initSecondarySwitch = () => {
    const styleTag = document.createElement("style");
    styleTag.type = "text/css";

    styleTag.innerHTML = `
      .form-switch-secondary .form-check-input:checked {
        background-color: var(--vz-secondary) !important;
        border-color: var(--vz-secondary) !important;
      }
    `;

    const headTags = document.getElementsByTagName("head");
    const headTag = headTags[headTags.length - 1];
    headTag.appendChild(styleTag);
  };

  const initialize = () => {
    initPrimarySwitch();
    initPrimarySelectedSwitch();
    initSecondarySwitch();
  };

  return {
    initialize,
  };
  
}