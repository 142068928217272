import { getUserDetails } from "../helpers/user/user.service";
import { encode } from "./jwt.js";
import store from "../state/store";

export const generateOnDemandToken = async () => {
  let userData = store.getters["user/getUserDetails"];

  if (!userData) {
    userData = await getUserDetails();
  }

  const token = await encode(
    {
      user_id: userData._id,
      username: userData.username,
      company_id: userData.company_id,
      role_id: userData.role_id,
      fb_type: parseInt(userData.firebase_env ?? "1"),
      is_contact: userData.is_contact,
    },
    {
      expireInSeconds: 604800,
    }
  );

  return token;
};
