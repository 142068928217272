export const hexToRgb = (hexColor) => {
  // Remove the '#' symbol if present
  hexColor = hexColor.replace("#", "");

  // Extract the RGB components
  var r = parseInt(hexColor.substr(0, 2), 16);
  var g = parseInt(hexColor.substr(2, 2), 16);
  var b = parseInt(hexColor.substr(4, 2), 16);

  // Return the RGB values as an object
  return { r, g, b };
};
