<script setup>
import { defineProps } from 'vue';
import Spinner from "@/components/spinner.vue";

const props = defineProps(['message'])
</script>

<template>
  <div
    class="loading"
  >
    <b-card class="loading-card">
      <div class="wrapper">
        <Spinner spinning position="center" :message="props.message"></Spinner>
      </div>
    </b-card>
  </div>
</template>

<style lang="scss" scoped>
.loading-card {
  width: 100%;
  max-width: 460px;
  border-radius: 8px;
  padding: 1.2rem;
  text-align: center;
  margin: 0 auto;

  .wrapper {
    width: 100%;
    height: 200px;
  }
}

.loading {
  display: flex;
  align-items: center;
  height: 100vh;

  .icon {
    margin: 0 auto;
    margin-bottom: 1.2rem;
  }
}
</style>
