import axios from "axios";
import { COMMON } from "@/constants/common";
import { CONFIG } from "@/constants/config";

const axiosInstance = axios.create({
  baseURL: CONFIG.BASE_URL,
});

// Request Interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Get the bearer token from local storage or wherever you store it
    const token = localStorage.getItem(COMMON.ACCESS_TOKEN);

    const app = config.headers['x-app'] ?? undefined;

    const isCandidatesApi = app === 'candidates-api';

    if (token && !config.headers.Authorization) {
      // Add the bearer token to the headers
      config.headers.Authorization = isCandidatesApi ? `Bearer ${token}` : token;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Response Interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle expired access token
    if (
      (error.response?.status === 401 &&
        error.response?.data?.error === "unauthorized_access") ||
      error.response?.data?.error === "session_expired"
    ) {
      let companyId = localStorage.getItem(COMMON.COMPANY);

      if (!companyId) companyId = localStorage.getItem(COMMON.COMPANY);

      localStorage.removeItem(COMMON.USER);
      localStorage.removeItem(COMMON.ACCESS_TOKEN);
      sessionStorage.removeItem(COMMON.SETTINGS);
      sessionStorage.removeItem(COMMON.USER);

      window.location.assign(`/${companyId}/login`);
    }

    if (
      error.response?.status === 401 &&
      error.response?.data?.errors?.logout
    ) {
      let companyId = sessionStorage.getItem(COMMON.COMPANY);

      if (!companyId) companyId = localStorage.getItem(COMMON.COMPANY);

      localStorage.removeItem(COMMON.USER);
      localStorage.removeItem(COMMON.ACCESS_TOKEN);
      sessionStorage.removeItem(COMMON.SETTINGS);
      sessionStorage.removeItem(COMMON.USER);

      window.location.assign(`/${companyId}/login`);
    }

    // Handle any other errors that occur during the response
    return Promise.reject(error);
  }
);

export default axiosInstance;
export { axiosInstance as axios };
