import jwtEncode from "jwt-encode";
import jwtDecode from "jwt-decode";
import { CONFIG } from "../constants/config";

export const now = () => Date.now() / 1000;

/**
 *
 * @param {object} payload
 * @param {{ time: number, expireInSeconds: number}} options
 * @returns
 */
export const encode = (payload, options) =>
  jwtEncode(
    {
      ...payload,
      exp:
        options?.time ??
        now() + options?.expireInSeconds ??
        parseInt(CONFIG.JWT_EXPIRATION),
    },
    CONFIG.JWT_SECRET
  );

/**
 *
 * @param {string} token
 * @returns object
 */
export const decode = (token) => jwtDecode(token, CONFIG.JWT_SECRET);
